






import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ReportFluctuationArrow',
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {};
  },
});
